<template>
  <ion-card-header class="filter-hint-header">
    <ion-card-subtitle :class="['filter-category-subtitle']">{{ i18n.$t('analysis.protocols.choose_filter_category') }}</ion-card-subtitle>
  </ion-card-header>

  <ion-card-header class="protocol-filter-container">
    <div class="chip-container">
      <ion-chip color="tertiary" outline :class="['filter-category-chip', 'all-categories', ((areAllMainCategoriesSelected) ? 'active' : undefined)]" @click="toggleSelectAllMainCategories()">
        <ion-icon :icon="filter"></ion-icon>
        <ion-label>{{ i18n.$t('analysis.protocols.filter_all') }}</ion-label>
      </ion-chip>
      <span class="horizontal-divider"></span>
      <ion-chip v-for="mainCategory in availableMainCategories" :key="mainCategory.id" color="primary" outline :class="['filter-category-chip', ((isMainCategorySelected(mainCategory) && !(areAllMainCategoriesSelected)) ? 'active' : undefined)]" @click="toggleSelectMainCategory(mainCategory)">
        <ion-label>{{ mainCategory.localizedDescriptor }}</ion-label>
      </ion-chip>
    </div>
    
    <div class="protocol-search-bar">
      <ion-searchbar :placeholder="i18n.$t('analysis.search.entry')" @keyup="blurOnEnter" @ionChange="searchEntryInput = $event.target.value"></ion-searchbar>
      <ion-button :fill="protocolHidingMode ? 'solid' : 'outline'" @click="toggleProtocolHidingMode">
        <ion-icon :icon="eye" class="on"></ion-icon>
        <hr class="separator">
        <ion-icon :icon="eyeOff" class="off"></ion-icon>
      </ion-button>
    </div>
  </ion-card-header>
  
  <ion-card class="entry-type-list-card">
    <ion-list v-if="selectedStageObject != null">
      <CollapsibleList class="list-level-0" v-model:open="isSelectedStageOpen"
        :primaryIndicator="(selectedStageObject.descriptor in selectedCategoryCounts) ? selectedCategoryCounts[selectedStageObject.descriptor].sum : null"
        :primaryIcon="checkmarkSharp"
        :tertiaryIndicator="stageVisibleEntryTypeCount(selectedStageObject.descriptor)"
        :tertiaryIcon="searchSharp"
        :showNoItemsIndicator="true"
        :noItemsIndicator="i18n.$t('analysis.protocols.none_found')"> <!-- FIXME Appearing indicators make title overflow and make selection of entry types difficult, when everything moves down or up! -->
        <template v-slot:label>
          <ion-label class="selected-stage-types-label collapsible-list-label">{{ i18n.$t('analysis.protocols.chosen_stage_protocols') }} <ion-icon :icon="gitCommit" class="stage-icon small"></ion-icon> <b>{{ selectedStageObject['localizedDescriptor'] }}</b></ion-label> <!--TODO In der Liste der Protokolle die bereits ausgefüllten abgehakt oder grün anzeigen mit entsprechendem success indicator und der Anzahl. -->
        </template>
        
        <EntryTypeSelection :orderedEntryTypes="selectedStageObject" v-model:selectedEntryTypes="selectedEntryTypesRef" :stageDescriptor="selectedStageObject.descriptor"
          :selectedSubCategoryCounts="(selectedStageObject.descriptor in selectedCategoryCounts) ? selectedCategoryCounts[selectedStageObject.descriptor]['sub_categories'] : null"
          :primaryIcon="checkmarkSharp"
          :foundEntryTypes="(selectedStageObject != null && filteredReportTypeIndex != null && filteredReportTypeIndex[selectedStageObject.descriptor] != null) ? filteredReportTypeIndex[selectedStageObject.descriptor]['visible'] : null"
          :tertiaryIcon="searchSharp"
          :hidingMode="protocolHidingMode"
          @setEntryTypeHidden="setEntryTypeHidden"
          :localizedMainCategories="localizedMainCategoryMapping"
          open> <!-- The selected subcategories are always open by default -->
        </EntryTypeSelection>
      </CollapsibleList>
    </ion-list>
  </ion-card>
  
  <ion-card class="entry-type-list-card"> <!-- TODO Maybe implement cards in report creation and view too? Just as the top level for standard protocol info. The report itself could have a slight appearance like paper? -->
    <ion-list>
      <CollapsibleList class="list-level-0" v-model:open="isUnselectedStagesOpen"
        :primaryIndicator="(unselectedCategorySum > 0) ? unselectedCategorySum : null"
        :primaryIcon="checkmarkSharp"
        :tertiaryIndicator="unselectedVisibleEntryTypeSum"
        :tertiaryIcon="searchSharp"
        :showNoItemsIndicator="true"
        :noItemsIndicator="i18n.$t('analysis.protocols.none_found')">
        <template v-slot:label>
          <ion-label class="other-stages-types-label collapsible-list-label">{{ i18n.$t('analysis.protocols.other_stages_protocols') }}</ion-label>
        </template>

        <template v-for="(stage, unselectedStageIndex) of unselectedStages" :key="unselectedStageIndex">
          <div v-if="isStageVisible(stage['descriptor'])">
            <ion-list-header class="stage-descriptor"><ion-icon :icon="gitCommit" class="stage-icon small"></ion-icon> <b>{{ stage['localizedDescriptor'] }}</b></ion-list-header>
            <EntryTypeSelection :orderedEntryTypes="stage" v-model:selectedEntryTypes="selectedEntryTypesRef" :stageDescriptor="stage['descriptor']"
              :selectedSubCategoryCounts="(stage['descriptor'] in selectedCategoryCounts) ? selectedCategoryCounts[stage['descriptor']]['sub_categories'] : null"
              :primaryIcon="checkmarkSharp"
              :foundEntryTypes="(stage != null && filteredReportTypeIndex != null && filteredReportTypeIndex[stage.descriptor] != null) ? filteredReportTypeIndex[stage.descriptor]['visible'] : null"
              :tertiaryIcon="searchSharp"
              :hidingMode="protocolHidingMode"
              @setEntryTypeHidden="setEntryTypeHidden"
              :localizedMainCategories="localizedMainCategoryMapping">
            </EntryTypeSelection>
          </div>
        </template>
      </CollapsibleList>
    </ion-list>
  </ion-card>

  <ion-card class="entry-type-list-card">
    <ion-list>
      <CollapsibleList class="list-level-0 hidden-entry-types" v-model:open="isHiddenEntryTypesOpen"
        :primaryIndicator="(hiddenCategorySum > 0) ? hiddenCategorySum : null"
        :primaryIcon="checkmarkSharp"
        :tertiaryIndicator="hiddenVisibleEntryTypeSum"
        :tertiaryIcon="searchSharp"
        :showNoItemsIndicator="true"
        :noItemsIndicator="i18n.$t('analysis.protocols.none_found')">
        <template v-slot:label>
          <ion-label class="hidden-types-label collapsible-list-label">{{ i18n.$t('analysis.protocols.hidden_protocols') }}</ion-label>
        </template>

        <!-- TODO Wie kann man später einen Fall als erledigt markieren, damit sich der Status zurücksetzt? Vielleicht automatisch den Index eines Pferdes auf 0 setzen und dann manuell Fall abschließen und damit den Index um 1 hochzählen und den REports diesen Index auch zuweisen. -->

        <template v-for="(stage, hiddenStageIndex) of hiddenStages" :key="hiddenStageIndex">
          <div v-if="isStageVisible(stage['descriptor'], true)">
            <ion-list-header class="stage-descriptor"><ion-icon :icon="gitCommit" class="stage-icon small"></ion-icon> <b>{{ stage['localizedDescriptor'] }}</b></ion-list-header>
            <EntryTypeSelection :orderedEntryTypes="stage" v-model:selectedEntryTypes="selectedEntryTypesRef" :stageDescriptor="stage['descriptor']"
              :selectedSubCategoryCounts="(stage['descriptor'] in hiddenSelectedCategoryCounts) ? hiddenSelectedCategoryCounts[stage['descriptor']]['sub_categories'] : null"
              :primaryIcon="checkmarkSharp"
              :foundEntryTypes="(stage != null && filteredReportTypeIndex != null && filteredReportTypeIndex[stage.descriptor] != null) ? filteredReportTypeIndex[stage.descriptor]['hidden'] : null"
              :tertiaryIcon="searchSharp"
              :hidingMode="protocolHidingMode"
              :treatAsHidden="true"
              @setEntryTypeHidden="setEntryTypeHidden"
              :localizedMainCategories="localizedMainCategoryMapping">
            </EntryTypeSelection> <!-- TODO Should we make matches of the search bold in the text itself? -->
          </div>
        </template>
      </CollapsibleList>
    </ion-list>
  </ion-card>
</template>

<script>
import { IonSearchbar, IonButton, IonIcon, IonList, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonLabel, IonChip } from '@ionic/vue';
import { computed, ref, watch } from 'vue';

import { useStore } from 'vuex';

import _ from 'lodash';

import CollapsibleList from '@/components/CollapsibleList.vue';
import EntryTypeSelection from '@/components/EntryTypeSelection.vue';

import { checkmarkSharp, searchSharp, gitCommit, eye, eyeOff, filter } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";
import { blurOnEnter } from '@/utils/interaction';

export default  {
  name: 'CategorizedEntryTypeSelection',
  components: { IonSearchbar, IonButton, IonIcon, IonList, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonLabel, IonChip, CollapsibleList, EntryTypeSelection },
  props: {
    selectedEntryTypes: Array,
    hiddenEntryTypeStructures: Array,
    currentSelectedStage: String,
    reportTypeIndex: Object
  },
  emits: ['update:selectedEntryTypes', 'setEntryTypeHidden', 'update:protocolHidingMode'],
  setup(props, { emit }){
    const i18n = useI18n();

    const store = useStore();

    const searchEntryInput = ref(null);

    const reportTypeIndex = computed(() => props.reportTypeIndex);

    const selectedEntryTypesProp = computed(() => props.selectedEntryTypes);

    const selectedEntryTypesRef = ref(selectedEntryTypesProp.value);

    const selectedStage = computed(() => props.currentSelectedStage);

    watch((selectedEntryTypesRef), (newSelectedTypes) => {
      emit('update:selectedEntryTypes', newSelectedTypes);
    });

    watch((selectedEntryTypesProp), (newSelectedTypes) => {
      selectedEntryTypesRef.value = newSelectedTypes;
    });

    const protocolHidingMode = ref(false);

    const toggleProtocolHidingMode = function() {
      protocolHidingMode.value = !protocolHidingMode.value;
    }

    watch((protocolHidingMode), (newState) => {
      emit('update:protocolHidingMode', newState);
    }, { immediate: true });

    const hiddenEntryTypeStructures = computed(() => props.hiddenEntryTypeStructures);

    const setEntryTypeHidden = function(hiddenEntryTypeStructure) {
      emit('setEntryTypeHidden', hiddenEntryTypeStructure); //Just forward the hiding to the parent. Updates the prop again
    }

    watch((selectedStage), (newStage, oldStage) => {
      if (newStage != oldStage) {
        isSelectedStageOpen.value = true;
        isUnselectedStagesOpen.value = false;
        isHiddenEntryTypesOpen.value = false;
      }
    });

    const isSelectedStageOpen = ref(true);
    const isUnselectedStagesOpen = ref(false);
    const isHiddenEntryTypesOpen = ref(false);

    //Removes all empty sections in all levels and empty sub_categories
    const filterEmptyEntryTypeRoutes = function(subCategories) {
      //First filter all sections in the entryTypes
      let filteredEntryTypeSections = _.map(subCategories, (subCategory) => {
        let entryTypes;
        if (!Array.isArray(subCategory.entry_types)) entryTypes = [];
        else {
          //Remove every section that is not followed by another element not being a section
          entryTypes = _.filter(subCategory.entry_types, (entryType, entryTypeIndex) => {
            //Give back all non-sections
            if (entryType.section != true) return true;
            
            //If it is a section, check what it is followed by
            //If we are out of bounds, the section is not followed by anything, so remove it
            if ((entryTypeIndex + 1) >= subCategory.entry_types.length) return false;
            
            //Include if the following entryType is not a section
            return (subCategory.entry_types[entryTypeIndex + 1].section != true);
          });
        }

        return {..._.omit(subCategory, 'entry_types'), entry_types: entryTypes};
      });

      //Remove all empty sub_categories that are not sections
      let filteredSubCategories = _.filter(filteredEntryTypeSections, (subCategory) => {
        //Give back all non-sections, if they have any entry_types
        if (subCategory.section != true) {
          return subCategory.entry_types.length > 0;
        }

        return true;
      })

      //Remove every section that is not followed by another subCategory not being a section
      return _.filter(filteredSubCategories, (subCategory, subCategoryIndex) => {
        //Give back all non-sections
        if (subCategory.section != true) return true;
            
        //If it is a section, check what it is followed by
        //If we are out of bounds, the section is not followed by anything, so remove it
        if ((subCategoryIndex + 1) >= filteredSubCategories.length) return false;
        
        //Include if the following subCategory is not a section
        return (filteredSubCategories[subCategoryIndex + 1].section != true);
      })
    }

    //Filter hidden entry types. Only include either visible or hidden, never both. Exclude hidden -> only include visible. Otherwise only include hidden ones!
    const filterHiddenEntryTypes = function(subCategories, stageDescriptor, excludeHidden = false) {
      if (!Array.isArray(subCategories)) return [];

      return _.map(subCategories, (subCategory) => {
        //If there are none hidden, include (or exclude) all respectively
        if (hiddenEntryTypeStructures.value == null || hiddenEntryTypeStructures.value.length == 0) {
          if (excludeHidden) return subCategory;
          else return [];
        }
        if (subCategory.section == true) return subCategory;
        
        if (!Array.isArray(subCategory.entry_types)) return [];
        
        return {
          ..._.omit(subCategory, 'entry_types'), 
          entry_types: _.filter(subCategory.entry_types, (entryType) => {
            if (entryType.section == true) return true;

            //Test with intersection if the current entryType is hidden
            let entryTypeStructure = {main_category: entryType.main_category, stage: stageDescriptor, sub_category: subCategory.descriptor, id: entryType.id};
            if (_.intersectionWith(hiddenEntryTypeStructures.value, [entryTypeStructure], _.isEqual).length > 0) {
              return !excludeHidden; //Just include the hidden ones
            } else {
              return excludeHidden; //Just include the visible ones
            }
          })
        }
      });
    }

    const visibleStages = computed(() => {
      return _.map(reportTypeIndex.value, (stage, index) => {
        let filtered_sub_categories = filterEmptyEntryTypeRoutes(filterHiddenEntryTypes(stage.sub_categories, stage.descriptor, true));
        return {..._.omit(stage, 'sub_categories'), index, sub_categories: filtered_sub_categories};
      });
    });

    const hiddenStages = computed(() => {
      let stages = _.map(reportTypeIndex.value, (stage, index) => {
        let filtered_sub_categories = filterEmptyEntryTypeRoutes(filterHiddenEntryTypes(stage.sub_categories, stage.descriptor, false));
        return {..._.omit(stage, 'sub_categories'), index, sub_categories: filtered_sub_categories};
      });

      return _.filter(stages, (stage) => {
        return (stage['sub_categories'] != null && stage['sub_categories'].length > 0);
      })
    });

    const unselectedStages = computed(() => {
      return _.filter(visibleStages.value, (stage) => {
        return (stage.descriptor != selectedStage.value && stage['sub_categories'] != null && stage['sub_categories'].length > 0);
      });
    });

    const selectedStageObject = computed(() => {
      return _.filter(visibleStages.value, (stage) => {
        return stage.descriptor == selectedStage.value;
      })[0];
    });

    //Group all stages and count the occurences of all subcategories in each stage
    const countCategories = function(entryTypeStructures) {
      if (Array.isArray(entryTypeStructures)) {
        return _.mapValues(_.groupBy(entryTypeStructures, 'stage'), (subCategories) => {  
          let count = {
            sub_categories: _.countBy(subCategories, 'sub_category'),
            sum: 0
          };
          count.sum = _.sum(_.values(count.sub_categories));
          return count;
        });
      } else {
        return {};
      }
    }

    const sumCategories = function(entryTypeStructures, excludeSelectedStage = false) {
      return _.sumBy(
        _.values(_.pickBy(entryTypeStructures, (stage, stageDescriptor) => {
          return ((!excludeSelectedStage) || selectedStageObject.value == null || stageDescriptor != selectedStageObject.value.descriptor);
        })), 'sum'
      );
    }
    
    //Count all categories without the hidden entry types
    const selectedCategoryCounts = computed(() => {
      return countCategories(_.differenceWith(selectedEntryTypesRef.value, hiddenEntryTypeStructures.value, _.isEqual));
    });

    //Count all the categories only with hidden entry types
    const hiddenSelectedCategoryCounts = computed(() => { 
      return countCategories(_.intersectionWith(selectedEntryTypesRef.value, hiddenEntryTypeStructures.value, _.isEqual));
    });

    const unselectedCategorySum = computed(() => {
      return sumCategories(selectedCategoryCounts.value, true);
    });

    const hiddenCategorySum = computed(() => {
      return sumCategories(hiddenSelectedCategoryCounts.value, false);
    });

    const excludedFilterMainCategories = ref({});

    const localizedMainCategories = computed(() => {
      let currentLocale = i18n.locale.value;
      return _.map(store.getters['reports/getMainCategories'], (mainCategory) => {
        return {
          id: mainCategory.id,
          localizedDescriptor: _.get(mainCategory, ['name', currentLocale])
        }
      });
    });

    const localizedMainCategoryMapping = computed(() => {
      return _.mapValues(_.keyBy(localizedMainCategories.value, 'id'), 'localizedDescriptor');
    });

    const availableMainCategories = computed(() => { //TODO Exclude general?
      return localizedMainCategories.value;
    });

    const isMainCategorySelected = computed(() => {
      return function(mainCategory) {
        return !(mainCategory.id in excludedFilterMainCategories.value);
      }
    });

    const areAllMainCategoriesSelected = computed(() => {
      return (Object.keys(excludedFilterMainCategories.value).length < 1);
    });

    const toggleSelectMainCategory = function(mainCategory) {
      if (mainCategory.id in excludedFilterMainCategories.value) {
        excludedFilterMainCategories.value = _.omit(excludedFilterMainCategories.value, mainCategory.id);
      } else {
        let newExludedMainCategories;
        //If all are selected just select this one, excluding all others
        if (areAllMainCategoriesSelected.value) {
          //Set all but the current one
          newExludedMainCategories = _.fromPairs(_.compact(_.map(availableMainCategories.value, (availableMainCategory) => {
            if (availableMainCategory.id == mainCategory.id) return null;
            return [availableMainCategory.id, true];
          })));
        } else {
          //Otherwise exclude this one
          newExludedMainCategories = { ...excludedFilterMainCategories.value, [mainCategory.id]: true };
        }
        //If all would be excluded, just select all again, always at least one selected
        if (_.every(availableMainCategories.value, (availableMainCategory) => availableMainCategory.id in newExludedMainCategories)) {
          newExludedMainCategories = {};
        }
        excludedFilterMainCategories.value = newExludedMainCategories;
      }
    }
    
    //On 'Select All' always all are selected, never none!
    const toggleSelectAllMainCategories = function() {
      excludedFilterMainCategories.value = {};
    }

    //TODO Test String "ung". In Entscheidungsfindung only the inner ones should be filtered. Stages are never filtered! Applies to "Weitere Protokolle"
    //Sub categories and sections that do not contain any visible entry types are hidden completely.
    //Other ones are either shown but indicated that none inside are matching, when the outer categories/sections match. Or the matched ones are shown and indicated as matches inside.
    //Zero means that none inside are matching but the subCategory/section matched
    const filteredReportTypeIndex = computed(() => { //TODO Adapt localized index to new format and use localized attribute here to filter
      let noSearchEntered = (searchEntryInput.value == null || searchEntryInput.value.length <= 0);
      if (noSearchEntered && Object.keys(excludedFilterMainCategories.value).length < 1) return null; //No search entered and no filtering of main_categories, don't filter

      const VISIBILITY_STATES = ['visible', 'hidden'];

      let stages = {};
      if (reportTypeIndex.value != null) {
        for (let stage of reportTypeIndex.value) {
          //Includes those that have at least one visible result in them
          let sub_categories_matching = {
            visible: {},
            hidden: {}
          };
          //Includes all sections that have at least one sub category with results after them
          let sub_category_sections_with_matches = {
            visible: [],
            hidden: []
          };

          let subCategorySectionFound = false;
          let currentSubCategorySection = null;

          if (stage.sub_categories != null) {
            for (let subCategory of stage.sub_categories) {
              //If the subCategory itself is the result, include all (if no other matches inside subCategory) but do not count unmatching
              let subCategoryMatches = noSearchEntered ? false : ( //If just the main_category filtering is enanbled, never match the sub_category, set to false and skip the next checks
                (subCategory.localizedDescriptor != null && subCategory.localizedDescriptor.toLowerCase().includes(searchEntryInput.value.toLowerCase())) || 
                (subCategory.descriptor != null && subCategory.descriptor.toLowerCase().includes(searchEntryInput.value.toLowerCase())) //TODO Use normalization function to also remove spaces in searchTerm and values?
              );

              //Skip sections, but include all after a found one 
              if (subCategory.section == true) {
                currentSubCategorySection = subCategory.descriptor;
                /*if (subCategoryMatches) subCategorySectionFound = true;
                else subCategorySectionFound = false;*/ //FIXME Disabled sub category section search because of unexpected behaviour. It shows all protocols inside sub_category, because of this match, but the inner protocols are hidden, if more specific matches are there. Not clear to users!
                continue;
              }

              //Skip empty sub_categories, that are not sections
              if (subCategory.entry_types == null) continue;

              //If the subCategory itself is the result, include all (if no other matches inside subCategory) but do not count unmatching. Also counts as found if the whole section is found.
              let subCategoryFound = ((subCategoryMatches == true) || (subCategorySectionFound == true));

              //Includes those that have been matched itself
              let entry_types_matching = {
                visible: [],
                hidden: []
              };
              //Includes those that have not been matched itself only by section
              let entry_types_matched_by_section = {
                visible: [],
                hidden: []
              };
              //Includes those that have been matched only by subCategory being found
              let entry_types_matched_by_sub_category = {
                visible: [],
                hidden: []
              };
              
              //Includes all sections that have at least one match after them
              let sections_with_matches = {
                visible: [],
                hidden: []
              };
              let sections_matched = { //Contains all sections that are matched themselves
                visible: [],
                hidden: []
              }; 
              let sections_matched_by_sub_category = { //Sub Category Matches are always handled as a fallback if nothing else inside has been matched
                visible: [],
                hidden: []
              }; 
              
              let sectionFound = false;
              let currentSection = null;
              
              if (subCategory.entry_types != null) {
                for (let entryType of subCategory.entry_types) {
                  //Search in localizedDescriptor or descriptor if they exist for matches
                  let entryTypeMatches = (noSearchEntered) ? ((entryType.section == true) ? false : true) : ( //If just the main_category filtering is enabled, include all non-sections
                    (entryType.localizedDescriptor != null && entryType.localizedDescriptor.toLowerCase().includes(searchEntryInput.value.toLowerCase())) || 
                    (entryType.descriptor != null && entryType.descriptor.toLowerCase().includes(searchEntryInput.value.toLowerCase()))
                  );

                  //Don't include sections, but include all after that if the section was found
                  if (entryType.section == true) {
                    currentSection = entryType.descriptor;
                    if (entryTypeMatches) sectionFound = true;
                    else sectionFound = false;
                    continue;
                  } else {
                    //Also check if the main_category should be exclude. If it has no main_category or is in the exclusion list, hide!
                    entryTypeMatches = entryTypeMatches && (entryType.main_category == null || (!(entryType.main_category in excludedFilterMainCategories.value)));
                  }

                  //Add it to a corresponding hidden match object, if it is hidden, by default in visible match object
                  let visibilityProperty = 'visible';
                  //Test with intersection if the current entryType is hidden
                  let entryTypeStructure = {main_category: entryType.main_category, stage: stage.descriptor, sub_category: subCategory.descriptor, id: entryType.id};
                  if (Array.isArray(hiddenEntryTypeStructures.value) && _.intersectionWith(hiddenEntryTypeStructures.value, [entryTypeStructure], _.isEqual).length > 0) {
                    visibilityProperty = 'hidden';
                  }

                  //Include all entry types that are not marked as sections and have been found through either a direct match or a higher order match.
                  //ID is now a hash consisting of name and category path, so its unique and not dependant on the version!
                  if (entryTypeMatches == true) {
                    entry_types_matching[visibilityProperty].push(entryType.id);
                  } else if (sectionFound == true) {
                    entry_types_matched_by_section[visibilityProperty].push(entryType.id);
                    if ((currentSection != null) && !(sections_matched[visibilityProperty].includes(currentSection))) sections_matched[visibilityProperty].push(currentSection);
                  } else if (subCategoryFound == true) {
                    entry_types_matched_by_sub_category[visibilityProperty].push(entryType.id);
                    if ((currentSection != null) && !(sections_matched_by_sub_category[visibilityProperty].includes(currentSection))) sections_matched_by_sub_category[visibilityProperty].push(currentSection);
                    continue;
                  } else {
                    continue;
                  } 

                  //If at least one entry was found, add the section too if it is valid and not yet added
                  if ((currentSection != null) && !(sections_with_matches[visibilityProperty].includes(currentSection))) sections_with_matches[visibilityProperty].push(currentSection);
                }
              }

              let entry_types_total_matched = {
                visible: _.union(entry_types_matching['visible'], entry_types_matched_by_section['visible']),
                hidden: _.union(entry_types_matching['hidden'], entry_types_matched_by_section['hidden'])
              };

              //Sort the found matches in an object for all possible visibility states
              for (let visibilityProperty of VISIBILITY_STATES) {
                //If at least one entry type or a entry type section was found or when the subCategory itself or one of its sections was found
                if ((entry_types_total_matched[visibilityProperty].length > 0) || (subCategoryFound == true)) {
                  //If at least one sub category was found, add the section too if it is valid and not yet added
                  if ((currentSubCategorySection != null) && !(sub_category_sections_with_matches[visibilityProperty].includes(currentSubCategorySection))) sub_category_sections_with_matches[visibilityProperty].push(currentSubCategorySection);

                  sub_categories_matching[visibilityProperty][subCategory.descriptor] = {
                    entry_types_shown: ((subCategoryFound == true) && (entry_types_total_matched[visibilityProperty].length <= 0)) ? entry_types_matched_by_sub_category[visibilityProperty] : entry_types_total_matched[visibilityProperty], //If the sub category is matched and we did not find anything in it, return all results otherwise just the correctly matched ones
                    entry_types_matching: entry_types_matching[visibilityProperty],
                    sections_with_matches: ((subCategoryFound == true) && (entry_types_total_matched[visibilityProperty].length <= 0)) ? sections_matched_by_sub_category[visibilityProperty] : sections_with_matches[visibilityProperty], //If the sub category is matched and we did not find anything in it, return all results otherwise just the correctly matched ones
                    sections_matching: _.union(sections_matched[visibilityProperty], sections_matched_by_sub_category[visibilityProperty]) //Give back all the sections that are added anyway because of higher order matches
                  };
                }
              }
            }
          }

          let currentStage = {
            visible: {},
            hidden: {}
          }

          //Count all matches for all possible visibility states
          for (let visibilityProperty of VISIBILITY_STATES) {
            let counts = {
              visible_count: 0,
              matching_count: 0
            }

            for (let subCategoryDescriptor of Object.keys(sub_categories_matching[visibilityProperty])) {
              counts.visible_count += sub_categories_matching[visibilityProperty][subCategoryDescriptor].entry_types_shown.length;
              counts.matching_count += sub_categories_matching[visibilityProperty][subCategoryDescriptor].entry_types_matching.length;
            }
            
            currentStage[visibilityProperty] = {
              ...counts,
              sub_categories: sub_categories_matching[visibilityProperty],
              sections_with_matches: sub_category_sections_with_matches[visibilityProperty]
            }
          }

          stages[stage.descriptor] = currentStage;
        }
      }

      return stages;
    });

    const isStageVisible = computed(() => {
      return (stageDescriptor, showHidden = false) => {
        if (filteredReportTypeIndex.value == null) return true; //No filter always means it's visible

        let visibilityProperty = (showHidden) ? 'hidden' : 'visible';
        return (filteredReportTypeIndex.value[stageDescriptor] != null &&
                filteredReportTypeIndex.value[stageDescriptor][visibilityProperty] != null && 
                filteredReportTypeIndex.value[stageDescriptor][visibilityProperty]['visible_count'] > 0);
      }
    })

    const stageVisibleEntryTypeCount = computed(() => {
      return (stageDescriptor, showHidden = false) => {
        let visibilityProperty = (showHidden) ? 'hidden' : 'visible';
        //If nothing is found, don't report any numbers
        if (filteredReportTypeIndex.value == null || 
            filteredReportTypeIndex.value[stageDescriptor] == null ||
            filteredReportTypeIndex.value[stageDescriptor][visibilityProperty] == null) return null; 
        let count = filteredReportTypeIndex.value[stageDescriptor][visibilityProperty]['visible_count'];
        if (count <= 0) return null;
        return count;
      }
    })

    const unselectedVisibleEntryTypeSum = computed(() => {
      if (filteredReportTypeIndex.value == null) return null;
      let sum = _.sumBy(
        _.values(_.pickBy(filteredReportTypeIndex.value, (stage, stageDescriptor) => {
          if (selectedStageObject.value == null) {
            return stageDescriptor != null; //If the selected stage is null, just return true if the stage != null --> different
          }
          //Else check the equality with the descriptor
          return stageDescriptor != selectedStageObject.value.descriptor;
        })),
        _.property(['visible', 'visible_count'])
      );
      if (sum <= 0) return null;
      return sum;
    });

    const hiddenVisibleEntryTypeSum = computed(() => {
      if (filteredReportTypeIndex.value == null) return null;
      let sum = _.sumBy(
        _.values(filteredReportTypeIndex.value),
        _.property(['hidden', 'visible_count'])
      );
      if (sum <= 0) return null;
      return sum;
    });

    return {
      i18n,
      blurOnEnter,
      searchEntryInput,
      selectedEntryTypesRef,
      toggleProtocolHidingMode,
      setEntryTypeHidden,
      hiddenStages,
      isStageVisible,
      unselectedStages,
      unselectedCategorySum,
      unselectedVisibleEntryTypeSum,
      hiddenCategorySum,
      hiddenVisibleEntryTypeSum,
      selectedCategoryCounts,
      stageVisibleEntryTypeCount,
      hiddenSelectedCategoryCounts,
      isSelectedStageOpen,
      isUnselectedStagesOpen,
      isHiddenEntryTypesOpen,
      protocolHidingMode,
      filteredReportTypeIndex,
      selectedStageObject,
      localizedMainCategoryMapping,
      availableMainCategories,
      isMainCategorySelected,
      areAllMainCategoriesSelected,
      toggleSelectMainCategory,
      toggleSelectAllMainCategories,

      checkmarkSharp,
      searchSharp,
      gitCommit,
      eye,
      eyeOff,
      filter
    };
  }
}
</script>

<style scoped>
ion-list-header {
  font-size: 1.2em;
  font-weight: 500;
  --color: var(--ion-color-step-850, #262626);
}

.protocol-filter-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
}

ion-card {
  background: var(--ion-background-color, #fff);
  --background: var(--ion-item-background);
}

.ios ion-card {
  margin-top: 10px;
  margin-bottom: 10px;
}

.list-level-0 {
  font-size: 20px;
  --color: var(--ion-color-tertiary-text);
  color: var(--color);
    background: var(--ion-background-color, #fff);
  --background: var(--ion-item-background);
}

.hidden-entry-types {
  opacity: 0.7;
}

.collapsible-list-label {
  font-weight: normal;
  white-space: normal;
  font-size: 1.15em;
}

.selected-stage-types-label b {
  font-weight: bold;
}

.stage-descriptor {
  font-size: 0.75em;
}

.ios .stage-descriptor {
  padding-bottom: 10px;
}

.ios .collapsible-list-label {
  font-size: 1em;
}

.stage-icon {
  vertical-align: -15%;
}

ion-list-header .stage-icon {
  margin-right: 5px;
}

.stage-icon.small {
  font-size: 1.25em;
  vertical-align: -18%;
}

.stage-hidden {
  display: none;
}

.entry-type-list-card:empty {
  display: none;
}

.entry-type-list-card ion-list {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -1px;
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.filter-hint-header {
  padding-block: 0px;
}

.protocol-search-bar, .protocol-filter-container {
  display: flex;
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.protocol-filter-container {
  flex-flow: column nowrap;
  padding-block: 0px;
  padding-inline: 10px;
}

.ios .protocol-filter-container {
  padding-inline: 16px;
}

.chip-container {
  margin: 10px 0px 5px 0px;
  padding: 0px;
  flex-grow: 1;
  flex-basis: 0px;
  flex-shrink: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.filter-category-subtitle {
  text-transform: none;
  margin-top: 10px;
}

.horizontal-divider {
  margin: 0px 10px;
  height: 1.4em;
  border-right: 2px solid var(--ion-color-medium);
}
.filter-category-chip.all-categories:not(.active) {
  border-color: rgba(var(--ion-color-base-rgb), 0.6);
}

.filter-category-chip:not(.all-categories):not(.active) {
  border-color: rgba(var(--ion-color-primary-text-rgb), 0.6);
}

.filter-category-chip.all-categories.active {
  color: var(--ion-color-tertiary-contrast, 0, 0, 0);
  background: rgba(var(--ion-color-tertiary-rgb, 0, 0, 0), 1);
}

.filter-category-chip.active {
  color: var(--ion-color-primary-contrast, 0, 0, 0);
  background: rgba(var(--ion-color-primary-rgb, 0, 0, 0), 1);
}

.protocol-search-bar ion-searchbar {
  padding-inline-start: 0px;
}

.protocol-search-bar ion-button {
  margin: 9px 0px 9px 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  font-size: 0.8em;
  height: unset;
  --border-width: 2px;
  --border-style: solid;
  --border-color: var(--ion-color-medium, #92949c);
  --background: var(--ion-color-medium, #92949c);
  --color: var(--ion-color-medium-contrast, #000000);
  --background-activated: var(--ion-color-medium, #92949c);
  --background-focused: var(--ion-color-medium, #92949c);
  --background-hover: var(--ion-color-medium, #92949c);
}

.protocol-search-bar ion-button.button-outline {
  --background: transparent;
  --color: var(--ion-color-medium, #92949c);
  --background-activated: transparent;
  --background-focused: var(--ion-color-medium, #92949c);
  --background-hover: transparent;
}

.protocol-search-bar ion-button > * {
  width: 1em;
}

.protocol-search-bar ion-button > .on {
  position: absolute;
  margin-right: 18px;
  margin-bottom: 16px;
}

.protocol-search-bar ion-button > .off {
  position: absolute;
  margin-left: 18px;
  margin-top: 16px;
}

.protocol-search-bar ion-button > .separator {
  width: 3.5em;
  transform: rotate(-40deg);
  height: 2px;
  background: currentColor;
}

</style>
